import React, { Fragment } from "react";
import { Layout, SEO } from "src/components";
import {
  MainSliderTemplate,
  HeroTemplate,
  PressBannerTemplate,
  MenuSectionTemplate,
  AwardsHeroTemplate,
  PrimaryContactHeroTemplate
} from "src/services/prismic/templates";
import { graphql } from "gatsby";
import { PrismicPage } from "src/graphqlTypes";

type IndexProps = {
  data: {
    prismicPage: PrismicPage;
  };
};

const Index = ({ data: { prismicPage: page } }: IndexProps) => {
  return (
    <Fragment>
      <SEO
        title={page.data?.title.text}
        description={page.data?.description.text}
        path="/"
      />

      <Layout>
        {page?.data?.body.map((slice, idx) => {
          switch (slice.__typename) {
            case "PrismicPageBodyHero":
              return idx === 0 ? (
                <MainSliderTemplate data={slice} key={idx} />
              ) : (
                <HeroTemplate data={slice} key={idx} />
              );
            case "PrismicPageBodyPressLogos":
              return <PressBannerTemplate data={slice} key={idx} />;
            case "PrismicPageBodyMenuSection":
              return <MenuSectionTemplate data={slice} key={idx} />;
            case "PrismicPageBodyAwards":
              return <AwardsHeroTemplate data={slice} key={idx} />;
            case "PrismicPageBodyContactPrimary":
              return <PrimaryContactHeroTemplate data={slice} key={idx} />;
            default:
              return null;
          }
        })}
      </Layout>
    </Fragment>
  );
};

export const query = graphql`
  query {
    prismicPage(uid: { eq: "index" }) {
      data {
        title {
          text
        }
        description {
          text
        }
        body {
          ...PrismicHero
          ...PrismicPressLogos
          ...PrismicMenu
          ...PrismicAwardsHero
          ...PrismicContactPrimary
        }
      }
    }
  }
`;

export default Index;
